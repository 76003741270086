import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/assets/css/reset.less'
import '@/assets/css/common.less'

// 不需要登录的页面

// router.beforeEach((to, from, next) => {
//   if (Object.keys(to.query).length > 0) {
//     store.commit('SET_CHANNEL', JSON.stringify(to.query))
//   }
//   let { token } = to.query
//   if (token) {
//     setToken(token)
//   } else {
//     token = getToken()
//   }
//   // const token = getToken()
//   // if (to.meta.title) {
//   //   document.title = to.meta.title
//   // }
//   // token 不存在 且不在白名单里
//   const toLogin = !token && whiteList.indexOf(to.path) === -1
//   if (toLogin) {
//     Dialog.confirm({
//       title: '系统提示',
//       message: '登录状态已过期，您可以继续留在该页面，或者重新登录',
//       confirmButtonText: '重新登录',
//       cancelButtonText: '取消',
//       type: 'warning',
//     }).then(() => {
//       store.dispatch('LogOut').then(() => {
//         location.href = '/phoneLogin'
//       })
//     })
//   } else {
//     next()
//   }
// })

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
